body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40%;
  color: darken(white, 70%);
  background: url(https://d1fybepl89cq7d.cloudfront.net/images/city-back.png) repeat-x bottom;
  background-size: 500px;
  background-color: darken(#f9fdff, 1%);

  @media only screen and (min-width:750px) {
    padding-bottom: 20%;
    background-size: 800px; }

  @media only screen and (min-width:1024px) {
    padding-bottom: 15%;
    background-size: 900px; } }

.rtc {
  &-logo {
    height: 60px;
    margin-bottom: 1.5rem;
    max-width: 90%;

    @media only screen and (min-width:750px) {
      max-width: initial;
      height: 110px; }

    img {
      width: 100%;
      height: 100%; } }

  &-sites {
    background-color: white;
    padding: 1rem;
    border: 1px solid darken(white, 6%);
    border-radius: 6px;

    @media only screen and (min-width:750px) {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      margin-top: 2rem; } }

  &-site {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 1rem 5rem 1rem 2rem;
    text-decoration: none;
    color: inherit;

    @media only screen and (min-width:750px) {
      margin: 0 2rem; }

    &-flag {
      width: 40px;
      margin: 0;
      margin-right: 1.5rem;
      overflow: hidden;

      @media only screen and (min-width:750px) {
        width: 70px; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

    &-name {
      font-size: 1.7rem;
      font-weight: bold; } } }

